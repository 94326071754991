import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/armeria/armeria/site/src/layouts/docs.tsx";
import versions from '../../../gen-src/versions.json';
export const pageTitle = "Using Armeria with Dropwizard";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Tip = makeShortcode("Tip");
const RequiredDependencies = makeShortcode("RequiredDependencies");
const Warning = makeShortcode("Warning");
const layoutProps = {
  pageTitle,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <h1 {...{
      "id": "using-armeria-with-dropwizard",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#using-armeria-with-dropwizard",
        "aria-label": "using armeria with dropwizard permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Using Armeria with Dropwizard`}</h1>
    <h6 {...{
      "className": "inlinePageToc",
      "role": "navigation"
    }}>{`Table of contents`}</h6>
    <ul>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#server-properties"
          }}>{`Server Properties`}</a></p>
      </li>
      <li parentName="ul">
        <p parentName="li"><a parentName="p" {...{
            "href": "#server-access-logs"
          }}>{`Server Access Logs`}</a></p>
        <ul parentName="li">
          <li parentName="ul"><a parentName="li" {...{
              "href": "#common"
            }}>{`common`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#combined"
            }}>{`combined`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#custom"
            }}>{`custom`}</a></li>
        </ul>
      </li>
    </ul>
    <Tip mdxType="Tip">
      <p>{`Visit `}<a parentName="p" {...{
          "href": "https://github.com/line/armeria/tree/main/examples/dropwizard"
        }}>{`armeria-examples`}</a>{` to find a fully
working example.`}</p>
    </Tip>
    <p>{`Dropwizard provides many features which are necessary for building a web application, such as metrics,
model validation, externalized and extensible configuration, etc. In addition, if your Dropwizard
application integrates with Armeria, you can leverage the following:`}</p>
    <ul>
      <li parentName="ul">{`Rich support for Apache `}<a parentName="li" {...{
          "href": "https://thrift.apache.org/"
        }}>{`Thrift`}</a>{` and `}<a parentName="li" {...{
          "href": "https://grpc.io/"
        }}>{`gRPC`}</a>{`,
including `}<a parentName="li" {...{
          "href": "/docs/server-docservice"
        }}>{`the fancy web console`}</a>{` that enables you to send Thrift and gRPC requests
from a web browser`}</li>
      <li parentName="ul">{`Ability to run HTTP REST service and RPC service in the same port`}</li>
      <li parentName="ul">{`Full HTTP/2 support for both server-side and client-side, including `}<inlineCode parentName="li">{`h2c`}</inlineCode>{` (plaintext HTTP/2)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.haproxy.org/download/1.8/doc/proxy-protocol.txt"
        }}>{`PROXY protocol`}</a>{` support which provides
interoperability with load balancers such as `}<a parentName="li" {...{
          "href": "https://www.haproxy.org/"
        }}>{`HAProxy`}</a>{` and
`}<a parentName="li" {...{
          "href": "https://aws.amazon.com/elasticloadbalancing/"
        }}>{`AWS ELB`}</a></li>
    </ul>
    <p>{`Armeria can be plugged in as the underlying HTTP server for a Dropwizard application by adding
the following dependency:`}</p>
    <RequiredDependencies boms={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-bom'
    }, {
      groupId: 'io.dropwizard',
      artifactId: 'dropwizard-bom',
      version: `${versions['io.dropwizard:dropwizard-core']}`
    }]} dependencies={[{
      groupId: 'com.linecorp.armeria',
      artifactId: 'armeria-dropwizard2'
    }]} mdxType="RequiredDependencies" />
    <p>{`The above dependencies import a new `}<inlineCode parentName="p">{`ServerFactory`}</inlineCode>{` for Dropwizard to run on by referring to the `}<inlineCode parentName="p">{`armeria`}</inlineCode>{` type
server in the Dropwizard configuration file. A user can customize the server configuration with the same properties
provided by `}<a parentName="p" {...{
        "href": "https://www.dropwizard.io/en/stable/manual/configuration.html#simple"
      }}>{`Dropwizard`}</a>{`'s `}<inlineCode parentName="p">{`SimpleServerFactory`}</inlineCode>{`.
The following is a simple example for configuring the server:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`server:
  type: armeria
  applicationContextPath: /
`}</code></pre>
    <p>{`For a user who wants to utilize Armeria, an `}<a parentName="p" {...{
        "href": "type://ArmeriaBundle:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/dropwizard/ArmeriaBundle.html"
      }}>{`type://ArmeriaBundle`}</a>{` implementation must be added to the
`}<inlineCode parentName="p">{`Application`}</inlineCode>{`.`}</p>
    <p>{`The user can further customize the server outside of the `}<inlineCode parentName="p">{`Configuration`}</inlineCode>{` as follows:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-java"
      }}>{`public class DropwizardArmeriaApplication extends Application<DropwizardArmeriaConfiguration> {

    public static void main(final String[] args) throws Exception {
        new DropwizardArmeriaApplication().run(args);
    }

    @Override
    public void initialize(final Bootstrap<DropwizardArmeriaConfiguration> bootstrap) {
        final ArmeriaBundle bundle = new ArmeriaBundle() {
            @Override
            public void configure(final ServerBuilder builder) {
                // Customize the server using the given ServerBuilder. For example:
                builder.service("/armeria", (ctx, res) -> HttpResponse.of("Hello, Armeria!"));

                builder.annotatedService(new HelloService());

                // You can also bind asynchronous RPC services such as Thrift and gRPC:
                // builder.service(THttpService.of(...));
                // builder.service(GrpcService.builder()...build());
            }
        };
        bootstrap.addBundle(bundle);
    }
}
`}</code></pre>
    <Tip mdxType="Tip">
      <p>{`If you are not familiar with Dropwizard, please refer to
`}<a parentName="p" {...{
          "href": "http://dropwizard.io/en/stable/getting-started.html"
        }}>{`Dropwizard Getting Started Guide`}</a>{` and
`}<a parentName="p" {...{
          "href": "http://dropwizard.io/en/stable/manual/core.html"
        }}>{`Dropwizard User Manual`}</a>{`.`}</p>
    </Tip>
    <h2 {...{
      "id": "server-properties",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#server-properties",
        "aria-label": "server properties permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Properties`}</h2>
    <Warning mdxType="Warning">
      <p>{`Not all Dropwizard configurations can be passed into the Armeria server. Currently supported parameters are:`}</p>
    </Warning>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`server:
  type: armeria
  gracefulShutdownQuietPeriodMillis: 5000
  gracefulShutdownTimeoutMillis: 40000
  maxRequestLength: 10485760
  maxNumConnections: 2147483647
  dateHeaderEnabled: true
  serverHeaderEnabled: false
  verboseResponses: false
  defaultHostname: "host.name.com"
  ports:
    - port: 8080
      protocol: HTTP
    - ip: 127.0.0.1
      port: 8081
      protocol: HTTPS
    - port: 8443
      protocols:
        - HTTPS
        - PROXY
  compression:
    enabled: true
    mimeTypes:
      - text/*
      - application/json
    excludedUserAgents:
      - some-user-agent
      - another-user-agent
    minResponseSize: 1KB
  ssl:
    keyAlias: "host.name.com"
    keyStore: "classpath:keystore.jks"
    keyStorePassword: "changeme"
    trustStore: "classpath:truststore.jks"
    trustStorePassword: "changeme"
  http1:
    maxChunkSize: 4096
    maxInitialLineLength: 4096
  http2:
    initialConnectionWindowSize: 1MB
    initialStreamWindowSize: 1MB
    maxFrameSize: 16384
    maxHeaderListSize: 8192
  proxy:
    maxTlvSize: 65319
  accessLog:
    type: common
`}</code></pre>
    <p>{`Where defined, the Armeria ServerFactory will prefer Armeria's default properties over Dropwizard's.
The following additional properties are able to be added to configure the `}<a parentName="p" {...{
        "href": "type://ServerBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/ServerBuilder.html"
      }}>{`type://ServerBuilder`}</a>{` before being
passed to the `}<a parentName="p" {...{
        "href": "type://ArmeriaBundle:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/dropwizard/ArmeriaBundle.html"
      }}>{`type://ArmeriaBundle`}</a>{`.`}</p>
    <table><thead parentName="table"><tr parentName="thead"><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "11rem"
            }}>Path</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><div style={{
              "width": "19rem"
            }}>Property</div></th><th parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="th">{`Description`}</p></th></tr></thead><tbody parentName="table"><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`jerseyEnabled`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Whether to enable JAX-RS resources defined by
Dropwizard (default: `}<inlineCode parentName="p">{`true`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`maxRequestLength`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The default server-side maximum length of
a request`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`maxNumConnections`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The maximum allowed number of open connections`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`dateHeaderEnabled`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Whether to include default `}<inlineCode parentName="p">{`"Data"`}</inlineCode>{` header
in the response header (default: `}<inlineCode parentName="p">{`true`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`serverHeaderEnabled`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Whether to include default `}<inlineCode parentName="p">{`"Server"`}</inlineCode>{` header
in the response header (default: `}<inlineCode parentName="p">{`false`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`verboseResponses`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Whether the verbose response mode is enabled
(default: `}<inlineCode parentName="p">{`false`}</inlineCode>{`)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`defaultHostname`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The default hostname of the default
`}<a parentName="p" {...{
                "href": "type://VirtualHostBuilder:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/VirtualHostBuilder.html"
              }}>{`type://VirtualHostBuilder`}</a></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`gracefulShutdownQuietPeriodMillis`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The number of milliseconds to wait after the
last processed request to be considered safe
for shutdown`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`server`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`gracefulShutdownTimeoutMillis`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The number of milliseconds to wait after going
unhealthy before forcing the server to shutdown
regardless of if it is still processing requests`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 3
          }}><p parentName="td"><inlineCode parentName="p">{`server.ports`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`port`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The port to run the server on (default: 8080)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`ip`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The IP address to bind to`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`iface`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The network interface to bind to`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 4
          }}><p parentName="td"><inlineCode parentName="p">{`server.compression`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`enabled`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Whether to enable the HTTP content encoding`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`mimeTypes`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The MIME Types of an HTTP response which are
applicable for the HTTP content encoding`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`excludedUserAgents`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The `}<inlineCode parentName="p">{`"User-Agent"`}</inlineCode>{` header values which are not
applicable for the HTTP content encoding`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`minResponseSize`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The minimum bytes for encoding the content of
an HTTP response`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 6
          }}><p parentName="td"><inlineCode parentName="p">{`server.ssl`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`enabled`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`Whether to enable SSL support`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`keyAlias`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The alias that identifies the key in
the key store`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`keyStore`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The path to the key store that holds the SSL
certificate (typically a jks file)`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`keyStorePassword`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The password used to access the key store`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`trustStore`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The trust store that holds SSL certificates`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`trustStorePassword`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The password used to access the trust store`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 2
          }}><p parentName="td"><inlineCode parentName="p">{`server.http1`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`maxChunkSize`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The maximum length of each chunk in an HTTP/1
response content`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`maxInitialLineLength`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The maximum length of an HTTP/1 response
initial line`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 5
          }}><p parentName="td"><inlineCode parentName="p">{`server.http2`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`initialConnectionWindowSize`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The initial connection-level HTTP/2 flow control
window size`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`initialStreamingWindowSize`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The initial stream-level HTTP/2 flow control
window size`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`maxFrameSize`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The maximum size of HTTP/2 frame that can be
received`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`maxStreamsPerConnection`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The maximum number of concurrent streams per
HTTP/2 connection. Unset means there is no limit
on the number of concurrent streams`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`maxHeaderListSize`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The maximum size of headers that can be received`}</p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 2
          }}><p parentName="td"><inlineCode parentName="p">{`server.accessLog`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`type`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The access log type that is supposed to be one
of `}<inlineCode parentName="p">{`"common"`}</inlineCode>{`, `}<inlineCode parentName="p">{`"combined"`}</inlineCode>{` or `}<inlineCode parentName="p">{`"custom"`}</inlineCode></p></td></tr><tr parentName="tbody"><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td"><inlineCode parentName="p">{`format`}</inlineCode></p></td><td parentName="tr" {...{
            "colspan": 1,
            "rowspan": 1
          }}><p parentName="td">{`The access log format string`}</p></td></tr></tbody></table>
    <h2 {...{
      "id": "server-access-logs",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#server-access-logs",
        "aria-label": "server access logs permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Server Access Logs`}</h2>
    <p>{`Armeria Server `}<inlineCode parentName="p">{`Access Logging <server-access-log>`}</inlineCode>{` is enabled by default when using the Armeria Server.
The default `}<a parentName="p" {...{
        "href": "type://AccessLogWriter:https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/AccessLogWriter.html"
      }}>{`type://AccessLogWriter`}</a>{` is `}<a parentName="p" {...{
        "href": "type://AccessLogWriter#common():https://javadoc.io/doc/com.linecorp.armeria/armeria-javadoc/latest/com/linecorp/armeria/server/logging/AccessLogWriter.html#common()"
      }}>{`type://AccessLogWriter#common()`}</a>{`, but this can be changed via the
following configuration.`}</p>
    <h3 {...{
      "id": "common",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#common",
        "aria-label": "common permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h3">{`common`}</inlineCode></h3>
    <p>{`Use NCSA common log format.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`server:
  type: armeria
armeria:
  accessLog:
    type: common
`}</code></pre>
    <h3 {...{
      "id": "combined",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#combined",
        "aria-label": "combined permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h3">{`combined`}</inlineCode></h3>
    <p>{`Use NCSA combined log format.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`server:
  type: armeria
  accessLog:
    type: combined
`}</code></pre>
    <h3 {...{
      "id": "custom",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#custom",
        "aria-label": "custom permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a><inlineCode parentName="h3">{`custom`}</inlineCode></h3>
    <p>{`Use your own log format. Refer to `}<a parentName="p" {...{
        "href": "/docs/server-access-log#customizing-a-log-format"
      }}>{`Customizing a log format`}</a>{`
for supported format tokens.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-yaml"
      }}>{`server:
  type: armeria
  accessLog:
    type: custom
    format: "...log format..."
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      